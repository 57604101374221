import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
import { PROJECT_CARBON_FIELD } from '@/shared/constants';

/**
 * get one supplier
 * @param {String, Number} userId
 * @param {Boolean} ofToday
 */
export const getTransports = async (userId = 0, ofToday = false) => {
	try {
		const {
			data: { GetTransports }
		} = await apollo.query({
			query: gql`
				query ($UserId: ID!, $OfToday: Boolean) {
					GetTransports(UserId: $UserId, OfToday: $OfToday) {
						${mutationResTransport}
					}
				}
			`,
			variables: {
				UserId: isNil(userId) ? 0 : parseInt(userId),
				OfToday: ofToday
			},
			fetchPolicy: 'no-cache'
		});

		return GetTransports;
	} catch (e) {
		console.log({ e });
	}
};

export const getTransport = async (transportId) => {
	if (isNil(transportId)) return;
	try {
		const {
			data: { GetTransport }
		} = await apollo.query({
			query: gql`
				query ($TransportId: ID!) {
					GetTransport(TransportId: $TransportId) {
						${mutationResTransport}
					}
				}
			`,
			variables: {
				TransportId: parseInt(transportId)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTransport;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} transportId
 */
export const getPassengers = async (transportId) => {
	if (isNil(transportId)) return;
	try {
		const {
			data: { GetPassengers }
		} = await apollo.query({
			query: gql`
				query ($TransportId: ID!) {
					GetPassengers(TransportId: $TransportId) {
						id
						userId
						name
						firstName
						phone
					}
				}
			`,
			variables: {
				TransportId: parseInt(transportId)
			},
			fetchPolicy: 'no-cache'
		});

		return GetPassengers;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} transportId
 */
export const getUsersAndPassengersOfProject = async () => {
	try {
		const {
			data: { GetUsersAndPassengersOfProject }
		} = await apollo.query({
			query: gql`
				query {
					GetUsersAndPassengersOfProject {
						id
						name
						firstName
						picture
						phone
						passengerId
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		return GetUsersAndPassengersOfProject;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} vehiculeId
 */
export const delPassenger = async (passengerId) => {
	if (isNil(passengerId)) return;
	try {
		const mutation = gql`
			mutation ($PassengerId: ID!) {
				DelPassenger(PassengerId: $PassengerId)
			}
		`;
		await apollo.mutate({
			mutation,
			variables: {
				PassengerId: parseInt(passengerId)
			}
		});
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} vehiculeId
 */
export const delTransport = async (transportId) => {
	if (isNil(transportId)) return;
	try {
		const mutation = gql`
			mutation ($TransportId: ID!) {
				DelTransport(TransportId: $TransportId)
			}
		`;
		await apollo.mutate({
			mutation,
			variables: {
				TransportId: parseInt(transportId)
			}
		});
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} vehiculeId
 */
export const addUpdPassenger = async ({
	transportId,
	passengerId = 0, // if new passenger
	updatedPassenger
}) => {
	if (isNil(transportId) || isNil(passengerId)) return;
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($TransportId: ID!, $PassengerId: ID!, $UpdatedPassenger: PassengerInput!) {
					AddUpdPassenger(TransportId: $TransportId, PassengerId: $PassengerId, UpdatedPassenger: $UpdatedPassenger) {
						name
						firstName
						phone
					}
				}
			`,
			variables: {
				TransportId: parseInt(transportId),
				PassengerId: parseInt(passengerId),
				UpdatedPassenger: updatedPassenger
			}
		});

		return data.AddUpdPassenger;
	} catch (e) {
		console.error({ e });
	}
};

export const updatedTransport = async (transportId, updatedTransport) => {
	if (isNil(transportId)) return;
	try {
		const mutation = gql`
			mutation ($TransportId: ID!, $UpdatedTransport: TransportInput!) {
				AddUpdTransport(
					TransportId: $TransportId
					UpdatedTransport: $UpdatedTransport
				) {
          ${mutationResTransport}
        }
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TransportId: parseInt(transportId),
				UpdatedTransport: updatedTransport
			}
		});

		return data;
	} catch (e) {
		console.error({ e });
	}
};

export const createTransport = async () => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($TransportId: ID!, $UpdatedTransport: TransportInput!) {
					AddUpdTransport(TransportId: $TransportId, UpdatedTransport: $UpdatedTransport) {
						${mutationResTransport}
					}
				}
			`,
			variables: {
				TransportId: 0,
				UpdatedTransport: {
					reason: 0,
					departureDate: new Date()
				}
			}
		});

		return data.AddUpdTransport;
	} catch (e) {
		console.error({ e });
	}
};

export const setTransportPosition = async ({ transportId, updatedTransport }) => {
	if (isNil(transportId)) return;
	try {
		const mutation = gql`
			mutation (
					$TransportId: ID!,
					$UpdatedTransport: TransportInput!
				) {
					SetTransportPosition(
					TransportId: $TransportId
					UpdatedTransport: $UpdatedTransport
				) {
            ${mutationResTransport}
          }
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TransportId: parseInt(transportId),
				UpdatedTransport: updatedTransport
			}
		});
		// console.log({ setTransportPosition: data });
		return data;
	} catch (e) {
		console.error({ e });
	}
};

export const startStopTransport = async ({ transportId, updatedTransport }) => {
	if (isNil(transportId)) return;
	try {
		const mutation = gql`
			mutation (
					$TransportId: ID!,
					$UpdatedTransport: TransportInput!
				) {
					StartStopTransport(
					TransportId: $TransportId
					UpdatedTransport: $UpdatedTransport
				) {
            ${mutationResTransport}
          }
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TransportId: parseInt(transportId),
				UpdatedTransport: updatedTransport
			}
		});

		return data;
	} catch (e) {
		console.error({ e });
	}
};

const mutationResTransport = `
id
projectId
callSheetId
taskId
depArrStd
userId
transportMean
kgCoTwo
km
extraInfo
fromAddressId
toAddressId
departureTime
departureDate
arrivalTime
ett
effDepartureTime
effArrivalTime
reason
reminderSent
reminderReceived
reminderAlarmSent
sendReminder
lateAlarmSent
eta
type
subType
additionalSubType
curLat
curLng
lateAlarmSent
fromLocationId
toLocationId
fromUserId
toUserId
vehiculeId
createdBy
createdAt
materialWeight
createdByUser {
  name
  firstName
}
user {
  name
  firstName
  phone
}
fromUser {
  name
  firstName
}
toUser {
  name
  firstName
}
fromLocation {
  setName
}
toLocation {
  setName
}
fromAddress {
  street
  number
  zip
  city
  country
}
toAddress {
  street
  number
  zip
  city
  country
}
vehicule {
  name
  numberPlate
}
passenger {
  id
  name
  firstName
  picture
  phone
}
documentXid
carbon {
  ${PROJECT_CARBON_FIELD}
}
`;
